import { render, staticRenderFns } from "./CardProduct2.vue?vue&type=template&id=4d47eda5&scoped=true"
import script from "./CardProduct2.vue?vue&type=script&lang=js"
export * from "./CardProduct2.vue?vue&type=script&lang=js"
import style0 from "./CardProduct2.vue?vue&type=style&index=0&id=4d47eda5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d47eda5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WishListBtn: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/WishListBtn.vue').default,ImageProduct: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ImageProduct.vue').default,CardProductCarousel: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/CardProductCarousel.vue').default,ColorsProduct: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ColorsProduct.vue').default})
