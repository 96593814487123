import { render, staticRenderFns } from "./ProductCardSizes.vue?vue&type=template&id=dca7ad7a&scoped=true"
import script from "./ProductCardSizes.vue?vue&type=script&lang=js"
export * from "./ProductCardSizes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca7ad7a",
  null
  
)

export default component.exports